import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Wrapper from "../primitives/wrapper"
import H1 from "../primitives/h"
import Container from "../primitives/container"
import P from "../primitives/p"
import theme from "../../config/theme"

const StyledH1 = styled(H1)`
  margin: 0 auto;
  margin-top: ${theme.spacing.medium};
  padding: 0 ${theme.spacing.large};
  font-weight: bold;
`

const Header = ({ headerImage }) => (
  <Wrapper mb="20px">
    <Container>
      {headerImage && (
        <Img
          fluid={headerImage.childImageSharp.fluid}
          alt="The convenient ferry alternative from Skiathos to Skopelos"
        />
      )}
      <StyledH1 style={{ fontSize: "1em" }}>
        Daily, on-demand boat transfers between Skiathos and Skopelos from €25.
      </StyledH1>
      <Slogan>Why wait? Get there now with SeaCab.</Slogan>
    </Container>
  </Wrapper>
)

const Slogan = styled(P)`
  margin-left: 24px;
  @media only screen and (min-width: 600px) {
    margin: 0 auto;
  }
`

export default Header
