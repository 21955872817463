import React from 'react';
import styled from 'styled-components'
import { Element, Link as ScrollLink } from "react-scroll"
import { Link } from "@reach/router"
import Collapsible from 'react-collapsible';
import Container from "../primitives/container"
import Heading from "../primitives/heading"
import P from "../primitives/p"

const StyledContainer = styled(Container)`
  padding: 24px;
`

const FaqsSection = () => (
  <StyledContainer>
    <Element name="faqs">
      <Heading>FAQs</Heading>
    </Element>
      <Collapsible trigger="What happens if my flight is delayed?">
        <P>If your flight into Skiathos is delayed and you miss your scheduled SeaCab transfer, we will wait for you as long as we can without disrupting the next transfer. If we are unable to wait any longer, we will take you across on the next available crossing for no additional cost.</P>
      </Collapsible>
      <Collapsible trigger="What happens in the event of bad weather?">
        <P>Our team is always watching the weather forecast in advance. If we see that there is a risk of your transfer being cancelled due to bad weather, we will notify you in advance and help you to organise an alternative form of transport. If we are unable to take you across, we will give you a credit equivalent to your missed trip, to be used any time on a SeaCab in the future.</P>
      </Collapsible>
      <Collapsible trigger="How can I get to Skopelos Town?">
        <P>The easiest way to get to Skopelos Town is to take the SeaCab from Skiathos to Loutraki (Glossa). From there you can take a  <a href="https://skopelos.com/listings/skopelos-buses/">bus</a>, <Link to="/skopelos-skiathos-taxis">taxi</Link>, or organise for your rental car to be dropped off at Loutraki port.</P>
      </Collapsible>
      <Collapsible trigger="Where will the boat be waiting?">
        <P>Please refer to the <ScrollLink
          style={{cursor:"pointer", color:"blue"}}
            to="where"
            spy={true}
            duration={500}
            smooth={true}
            offset={-74}
          >
            Where?
          </ScrollLink> section above on our home page.</P>
      </Collapsible>
      <Collapsible trigger="How can I get to Alonissos?">
        <P>If the ferry/boat transfer to Alonissos from Skiathos is full, you can take a SeaCab to loutraki in advance. Once there, some people will depart the ferry which will allow you to hop on and continue the journey to Alonissos.</P>
      </Collapsible>
      <Collapsible trigger="How do I get between Skiathos airport and the port of Skiathos?">
        <P>It's just a 5 minute taxi journey between Skiathos aiport and the port of Skiathos. There are usually taxis waiting at both ends, but if you are on a tight schedule we can help organise a taxi to be waiting for you on either end.</P>
      </Collapsible>
      <Collapsible trigger="How do I get from Agnondas port to Skopelos Town?">
        <P>From the port of Agnondas it's just a quick 15 minute taxi ride into Skopelos town. We will happily provide you with the number of a taxi driver if needed.</P>
      </Collapsible>
      <Collapsible trigger="Where do I get more local information?">
        <P>For more local information on Skopelos please see here:<br/><a href="https://skopelos.gr/" target="_blank">Skopelos.gr</a><br/><a href="https://skopelos.com/" target="_blank">Skopelos.com</a></P>
      </Collapsible>
  </StyledContainer>

    );

export default FaqsSection;
