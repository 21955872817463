import styled from "styled-components"
import theme from "../../config/theme"

export default styled.div`
  width: 100%;
  background: ${({ color }) => theme.colors && theme.colors[color]};
  margin-bottom: ${({ mb }) => mb};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
`
