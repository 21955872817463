import React from "react"
import styled from "styled-components"
import P from "../primitives/p"
import Container from "../primitives/container"
import Img from "gatsby-image"
import theme from "../../config/theme"

const StyledContainer = styled(Container)`
  padding: ${theme.spacing.large};
  background: #1E15C1;
  color: white;
`

const ParagraphSection = ({ boatInteriorImage }) => (
  <>
    {boatInteriorImage && (
      <Img
        fluid={boatInteriorImage.childImageSharp.fluid}
        alt="The SeaCab boat can transport up to 8 passengers at a time from Skopelos to Skiathos"
      />
    )}
    <StyledContainer>
      <P>
        Our comfortable 8-seater water-taxi transfer is a welcome supplement to
        the existing ferry system connecting Skiathos and Skopelos. If you're
        wondering how to get from Skiathos to Skopelos, look no further.
        <br />
        <br />
        With an average crossing time of just 15 minutes between Skiathos and
        Loutraki (Glossa), SeaCab allows you to spend more time enjoying the
        beach, spending time with your loved ones and sampling delicious Greek
        cuisine.
        <br />
        <br />
        So why wait? Get there now with SeaCab.
      </P>
    </StyledContainer>
  </>
)

export default ParagraphSection
