import styled from "styled-components"
import List from "../primitives/list"
import more from "../../images/more.svg"
import theme from "../../config/theme"

const MoreList = styled(List)`
  list-style-image: url(${more});
  margin-top: ${theme.spacing.small};
  margin-bottom: 0;
`

export default MoreList
