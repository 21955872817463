import styled from "styled-components"
import theme from "../../config/theme"

const List = styled.ul`
  margin-top: 4px;
  margin-bottom: 16px;
  li {
    :not(:first-child) {
      margin-top: ${theme.spacing.small};
    }
  }
`

export default List
