import React from "react"
import styled from "styled-components"

const StyledImage = styled.img`
  width: 100%;
  margin: 0 auto;
`

const Image = ({ src, className, alt }) => (
  <StyledImage className={className} src={src} alt={alt} />
)

export default Image
