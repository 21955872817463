import styled from "styled-components"
import theme from "../../config/theme"

const Button = styled.button`
  background: ${theme.colors && theme.colors.orange};
  height: 3rem;
  width: 13rem;
  border-radius: 9px;
  font-size: 20px;
  color: #ffffff;
  outline: none;
  :hover {
    border: 2px solid ${theme.colors && theme.colors.orange};
    background: white;
    color: ${theme.colors && theme.colors.orange};
  }
  transition: 0.2s ease;
`

export default Button
