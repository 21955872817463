import React from "react"
import Header from "../components/sections/header"
import ParagraphSection from "../components/sections/paragraph-section"
import ContactButtonsSection from "../components/sections/contact-buttons-section"
import ScheduleAndRates from "../components/sections/schedule-and-rates"
import WhereSection from "../components/sections/where-section"
import FaqsSection from "../components/sections/faqs-section"
import Layout from "../components/constructs/layout"

const Home = ({ headerImage, boatImage, boatInteriorImage }) => {
  return (
    <Layout menu>
      <Header headerImage={headerImage} />
      <ContactButtonsSection boatImage={boatImage} />
      <ScheduleAndRates />
      <WhereSection />
      <ParagraphSection boatInteriorImage={boatInteriorImage} />
      <FaqsSection/>
    </Layout>
  )
}

export default Home
