import React from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import Button from "../primitives/button"
import Container from "../primitives/container"
import MailIcon from "../icons/mail"
import Img from "gatsby-image"

const StyledLinkSecond = styled(Link)`
  margin: 0 auto 20px;
`

const StyledMailIcon = styled(MailIcon)`
  position: relative;
  margin-right: 12px;
  top: 4px;
`

const ContactButtons = ({ boatImage, theme }) => (
  <Container>
    <StyledLinkSecond to="/enquire">
      <Button>
        <StyledMailIcon />
        ENQUIRE
      </Button>
    </StyledLinkSecond>
    {boatImage && (
      <Img
        fluid={boatImage.childImageSharp.fluid}
        alt="The fast SeaCab boat is a convenient alternative to traditional ferries from Skiathos to Skopelos"
      />
    )}
  </Container>
)

export default ContactButtons
