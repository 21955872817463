import React, { useState } from "react"
import styled from "styled-components"
import { Element } from "react-scroll"
import {ports} from "../../content/content"
import Wrapper from "../primitives/wrapper"
import Image from "../primitives/image"
import Map from "../../images/map.svg"
import Heading from "../primitives/heading"
import Cont from "../primitives/container"
import theme from "../../config/theme"

const Container = styled(Cont)`
  padding: 24px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

function Where() {
  const [port, setPort] = useState("skiathos")
  const changePort = e => setPort(e.target.value)
  return (
    <>
      <StyledWrapper>
        <Image src={Map} alt="How to get from Skiathos to Skopelos" />
      </StyledWrapper>
      <Container bgColor="blue2">
        <Element name="where">
          <Heading color="blue3">Where to find us</Heading>
        </Element>
        <div>
          <span>
            <select
              onChange={changePort}
              style={{
                backgroundColor: theme.colors.blue3,
                marginLeft: "8px",
                height: "48px",
                color: "white",
              }}
            >
              <option value="skiathos">Skiathos</option>
              <option value="loutraki">Loutraki</option>
              <option value="adrina">Adrina Hotel</option>
              <option value="elios">Elios</option>
              <option value="agnondas">Agnondas</option>
            </select>
          </span>
        </div>
        {port ? (
          <>
            <h1>{ports[port].title}</h1>
            <iframe
              title="mapIframe"
              src={ports[port].map}
              width="100%"
              minHeight="300px"
              style={{
                minHeight: "300px",
                border: "#1E15C1 solid 2px",
                borderRadius: "16px",
              }}
            ></iframe>
            <p>{ports[port].information}</p>
          </>
        ) : (
          <h1>Select a port</h1>
        )}
      </Container>
    </>
  )
}

export default Where
