import React from "react"
import styled from "styled-components"
import { Element } from "react-scroll"
import Container from "../primitives/container"
import P from "../primitives/p"
import MoreList from "../constructs/more-list"
import Heading from "../primitives/heading"
import theme from "../../config/theme"

const StyledHeading = styled(Heading)`
  margin-bottom: ${theme.spacing.medium};
`

const StyledContainer = styled(Container)`
  padding: ${theme.spacing.large};
  align-items: start;
`

const LastList = styled(MoreList)`
  margin-bottom: 0;
`

const ScheduleAndRates = () => (
  <StyledContainer>
    <Element name="prices">
      <StyledHeading>Prices, schedule and routes</StyledHeading>
    </Element>
    <P>
      <strong>Daily water-taxi service (08:30 - 20:30)</strong>
    </P>
    <MoreList>
      <li>
        <P>
          Water-taxi transfers between Skiathos and Skopelos (Loutraki port,
          Glossa), operating hourly on demand:
        </P>
        <P>From €25</P>
        <P>+- 15 minutes travel time</P>
      </li>
    </MoreList>
    <br />
    <P>
      <strong>
        Private on-demand hire (Maximum 8 passengers, 08:30 - 20:30)
      </strong>
    </P>
    <LastList>
      <li>
        <P>Skiathos - Loutraki (Glossa):</P>
        <P>Price: €200</P>
        <P>+- 15 minutes travel time</P>
      </li>
      <li>
        <P>Skiathos - Elios / Adrina Hotel:</P>
        <P>Price: €175</P>
        <P>+- 25 minutes travel time</P>
      </li>
      <li>
        <P>Skiathos - Agnondas</P>
        <P>Price: €275</P>
        <P>+- 35 minutes travel time</P>
      </li>
      <li>
        <P>All other routes:</P>
        <P>€22/mile confirmed on request</P>
        <P>Contact us for a competitive quote</P>
      </li>
    </LastList>
  </StyledContainer>
)

export default ScheduleAndRates
